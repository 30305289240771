import {
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd"
import React, { useEffect, useState } from "react"
import Button from "src/components/MyButton/Button"
import TableCustom from "src/components/Table/CustomTable"
import { MainTableData } from "src/components/Table/CustomTable/styled"
import FormService from "src/services/FormSevice"
import { StyleModalReport } from "./styled"
import SunEditor from "suneditor-react"

const ListModal = ({ open, onOk, onCancel, getData, handleChangeSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState()
  const [form] = Form.useForm()
  const [pagination, setPagination] = useState({
    // PageSize: 50,
    // CurrentPage: 1,
    page_size: 10,
    page_number: 1,
    status: undefined,
    team_type: 0,
  })
  const [listlevel, setListlevel] = useState()

  const getListlevel = async () => {
    try {
      setLoading(true)
      const res = await FormService.getLevelError()
      if (res.isOk) {
        let ls = res.data.map((item, index) => {
          return {
            value: item?.id || index + 1,
            label: item?.value,
          }
        })
        setListlevel(ls)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListlevel()
    form.setFieldsValue({})
  }, [])

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        // <div className="text-center">
        //     {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        // </div>
        <div className="text-center">
          {(pagination.page_number - 1) * pagination.page_size + idx + 1}
        </div>
      ),
      align: "center",
    },
    {
      title: (
        <>
          <MainTableData>Tên báo cáo/Mô tả</MainTableData>
        </>
      ),
      key: "form_name",
      render: (val, record) => (
        <Tooltip
          title={
            <>
              <div>Tên báo cáo/Mô tả: {record?.form_name}</div>
            </>
          }
        >
          <MainTableData>{record?.form_name}</MainTableData>
        </Tooltip>
      ),
    },

    {
      title: "Mức độ",
      dataIndex: "value",
      key: "value",
      render: (val, record) => (
        <Tooltip title={<div>{record?.allcode?.value}</div>}>
          <MainTableData>{record?.allcode?.value}</MainTableData>
        </Tooltip>
      ),
      align: "center",
      width: 80,
    },
    {
      title: "Người gửi",
      dataIndex: "fullname",
      key: "fullname",
      width: 150,
      align: "center",
      render: (val, record) => (
        <Tooltip title={<div>{record?.User?.fullname}</div>}>
          <MainTableData>{record?.User?.fullname}</MainTableData>
        </Tooltip>
      ),
    },
    {
      title: "Điểm",
      dataIndex: "point",
      key: "point",
      width: 80,
      render: (val, record, idx) => (
        <Form.Item name={["points", idx]} initialValue={record?.point}>
          <InputNumber size="large" min={1} max={100000} />
        </Form.Item>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "staus",
      key: "staus",
      width: 100,
      render: (val, record) => (
        <>
          {record?.status === 0 ? (
            "Đang chờ"
          ) : record?.status === 1 ? (
            <div style={{ color: "blue", fontWeight: "600" }}>Tiếp nhận</div>
          ) : (
            <div style={{ color: "red", fontWeight: "600" }}>Từ chối</div>
          )}
        </>
      ),
    },
  ]
  const handleChangeForm = async () => {
    const values = await form.validateFields()
    const formIdsArray = open?.dataSelect.map(record => record.id)
    const body = {
      record: {
        ...values,
        form_id: formIdsArray,
      },
      status: open?.status,
    }
    handleChangeSubmit(body)
  }
  return (
    <div>
      <Modal
        title={"Danh sách báo cáo"}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              onClick={() => handleChangeForm()}
              htmlType="submit"
            >
              {open?.status === 1 ? "Nhận" : "Từ chối"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          className="mr-20"
          scrollToFirstError
        >
          <Row>
            {/* <Col span={24}>Danh sách báo cáo</Col> */}
            <Col span={24}>
              <div style={{ width: "100%" }}>
                <TableCustom
                  loading={loading}
                  isPrimary
                  columns={columns}
                  dataSource={open?.dataSelect}
                  sticky={{ offsetHeader: -14 }}
                  scroll={{ x: "800px" }}
                  style={{ marginBottom: "4px" }}
                  showPagination
                  rowKey={record => `${record?.id}`}
                  footerLeft={<div className="d-flex mt-20" />}
                  widthScroll={1200}
                />
              </div>
            </Col>
            <Col span={24}>
              <Form.Item label="Mức độ" name="level">
                {/* <Input allowClear placeholder="Nhập" /> */}
                <Select allowClear options={listlevel} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <StyleModalReport>
                <Form.Item
                  label="Đánh giá khuyến nghị"
                  name="feedback"
                  rules={[
                    {
                      required: true,
                      message: "Poc không được để trống",
                    },
                  ]}
                >
                  <SunEditor height="200px" />
                </Form.Item>
              </StyleModalReport>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default ListModal
