import { Col, Form, Input, Row, Spin } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import TableCustom from "src/components/Table/CustomTable"
import PractiseService from "src/services/PractiseService"

const OperationsManagement = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
    practise_id: id,
  })
  const [total, setTotal] = useState()

  const [dataNoti, setDataNoti] = useState()
  const handleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form?.validateFields()
      const requestBody = {
        ...values,
        practise_id: id,
      }
      const res = await PractiseService.sendNotiToAll(requestBody)

      if (res.isOk) {
        form.resetFields()
        Notice({ isSuccess: true, msg: res.message })
        getDataUser()
      }
    } finally {
      setLoading(false)
    }
  }
  const getDataUser = async item => {
    try {
      setLoading(true)
      const res = await PractiseService.getNotiToAll({
        ...pagination,
      })
      if (res.isOk) {
        setDataNoti(res?.data)
        setTotal(res?.total)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDataUser()
  }, [pagination])
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.page_size * (pagination.page_number - 1)}
        </div>
      ),
      align: "center",
    },
    {
      title: "Người gửi",
      dataIndex: "avatar_url",
      key: "avatar_url",
      render: (val, record) => (
        <>{record?.User?.fullname || record?.Organization?.tag}</>
      ),
    },
    {
      title: "Nội dung thông báo",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (val, record) => (
        <>{moment(record?.createdAt).format("DD/MM/YYYY HH:mm") || ""}</>
      ),
    },
  ]
  return (
    <div>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Spin spinning={loading}>
          <Row gutter={[16, 0]} className="mr-40">
            <Col span={24}>
              <h2 className="tile-01">Quản lý hoạt động </h2>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                className="title-noti"
                label="1. Nhập thông báo"
                name="message"
              >
                <Input.TextArea placeholder="Nhập"></Input.TextArea>
              </Form.Item>
            </Col>
            <Col span={24} className="d-flex ">
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "40px",
                  marginTop: "40px",
                  display: "flex",
                  width: "100%",
                  padding: "0px 15px 0px 0px",
                }}
              >
                <Button
                  className=""
                  style={{
                    marginRight: "8px",
                    background: "#248C4E",
                    color: "white",
                  }}
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Gửi thông báo
                </Button>
              </div>
            </Col>
            <Col span={24}>
              <h2 className="tile-01">Lịch sử gửi thông báo</h2>
            </Col>
            <Col span={24}>
              <div style={{ width: "100%" }}>
                <TableCustom
                  loading={loading}
                  isPrimary
                  columns={columns}
                  dataSource={dataNoti}
                  sticky={{ offsetHeader: -14 }}
                  scroll={{ x: "800px" }}
                  style={{ marginBottom: 4 }}
                  pagination={{
                    hideOnSinglePage: total <= 10,
                    current: pagination.page_number,
                    PageSize: pagination.page_size,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, page_size) => {
                      setPagination({
                        ...pagination,
                        page_number: page,
                        page_size,
                      })
                      // getListRole({ ...pagination, CurrentPage: page, PageSize })
                    },
                  }}
                  showPagination
                  // rowKey={record => `${record?.roleId}${record?.LastUpdate}`}
                  footerLeft={<div className="d-flex mt-20" />}
                  widthScroll={1200}
                  // textEmpty="Không có Nhóm quyền nào!"
                />
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </div>
  )
}

export default OperationsManagement
