// User
export const apiInsertUser = "User/Insert"
export const apiDeleteUser = "User/Delete"
export const apiDetailUser = "User/GetDetail"
export const apiUpdateUser = "User/Update"
export const apiGetListUser = "User/GetList"
export const apiImportUser = "User/ImportUser"
export const apiExportUser = "User/ExportUser"
export const apiChangeStatus = "User/ChangeStatus"
export const apiGetTemplateFileImportUser = "User/GetTemplateFileImportUser"
export const apiGetListGuest = "User/GetListGuest"
export const apiGetAccount = "User/GetAccount"
export const apiExportGuest = "User/ExportGuest"
export const apiImportGuest = "User/ImportGuest"
export const apiGetTemplateFileImportGuest = "User/GetTemplateFileImportGuest"
export const apiUpdateAccount = "User/UpdateAccount"
export const apiReplacePassword = "/chuyendoiso/api/v1/UpdateUserPassword"
export const apiGetInforUser = "/chuyendoiso/api/v1/GetUserByID"
export const apiGetListOrg = "/chuyendoiso/api/v1/GetListOrg"
export const apiExportUserToFile = "/chuyendoiso/api/v1/ExportUserToFile"
export const apiImportUserByFile = "/chuyendoiso/api/v1/ImportUserByFile"
export const apiGetInfoDetails = "/quytrinh/api/v1/GetInfoDetails"

export const apiChangeInfor = "User/ChangeInfor"
export const apiChangeImgUser = "User/ChangeImgUser"
export const apiInsertRecruiter = "User/InsertRecruiter"
export const apiGetListUserInDept = "User/GetListUserInDept"
export const apiGetListRecruterByUserID = "User/GetListRecruterByUserID"

export const apiGetAllAccountUser = "/chuyendoiso/api/v1/GetUsers"
export const apiInsertAccountUser = "User/InsertAccountUser"
export const apiUpdateAccountUser = "/chuyendoiso/api/v1/UpdateUser"
export const apiDeletaAccountUser = "User/DeleteAccountUser"
export const apiGetAllUserDirectory = "User/GetAllUserDirectory"
export const apiInsertGuest = "User/InsertGuest"
