import {
  Col,
  ConfigProvider,
  InputNumber,
  Row,
  Segmented,
  Space,
  Tooltip,
  Form,
  Select,
} from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import TableCustom from "src/components/Table/CustomTable"
import { MainTableData } from "src/components/Table/CustomTable/styled"
import STORAGE, { getStorage } from "src/lib/storage"
import PractiseService from "src/services/PractiseService"

const Evaluate = ({ formDetail }) => {
  const { id } = useParams()
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_number: 1,
    team_type: 1,
    input: undefined,
  })
  const [data, setData] = useState([])
  const [dataRanking, setDataRanking] = useState([])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const [scores, setScores] = useState({})
  const [selectedTeam, setSelectedTeam] = useState(null) // State for the selected team
  const [dataTotal, setDataTotal] = useState()
  const [buttonBTC, setButtonBTC] = useState(false)
  const [button, setButtonData] = useState()

  useEffect(() => {
    if (!!userInfo) {
      userInfo?.role?.practise?.forEach(user => {
        if (user?.practise_id === id) {
          switch (user?.role_group_id) {
            case 7:
              setButtonBTC(true)
              break
            // Add other cases if needed
            default:
              setButtonBTC(false)
              break
          }
        }
      })
    }
  }, [userInfo, id])
  const getDataPdf = async () => {
    try {
      setLoading(true)
      const apiCall =
        pagination.team_type === 3
          ? PractiseService.getRankingOfPractise
          : PractiseService.getEvaluateOfPractise
      const resp = await apiCall({
        ...pagination,
        practise_id: id,
      })
      if (resp.isOk) {
        setButtonData(resp?.button)
        if (pagination.team_type !== 3) {
          setData(resp?.data.data)
        } else {
          setDataRanking(resp?.data)
        }
        setTotal(resp?.data.total)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDataPdf()
  }, [pagination])

  const extractTeamNames = data => {
    const teamNames = new Set()
    data?.forEach(item => {
      item?.point?.forEach(point => {
        teamNames?.add(point.team_name)
      })
      item?.children?.forEach(child => {
        child?.point?.forEach(point => {
          teamNames?.add(point.team_name)
        })
      })
    })
    return Array.from(teamNames)
  }

  const teamNames = extractTeamNames(data)
  const handleInputChange = (value, recordId, teamName) => {
    setScores(prevScores => ({
      ...prevScores,
      [recordId]: {
        ...prevScores[recordId],
        [teamName]: value,
      },
    }))
  }

  const renderPointValues = (record, teamName) => {
    const teamPoint = record.point.find(p => p.team_name === teamName)
    return (
      <Form form={form} layout="vertical" style={{ width: "100%" }}>
        <Form.Item
          name={`${record.id}_${teamName}`}
          initialValue={teamPoint?.value}
        >
          <InputNumber
            min={1}
            max={record?.score}
            onChange={val => handleInputChange(val, record.id, teamName)}
          />
        </Form.Item>
      </Form>
    )
  }

  const handleResult = async () => {
    try {
      setLoading(true)
      const res = await PractiseService.allowPublishResult({
        practise_id: id,
      })
      if (res.isOk) {
        Notice({ msg: res.message, isSuccess: true })
      }
    } finally {
      setLoading(false)
    }
  }
  const handleTeamTotalUpdate = () => {
    const teamData = total.listTeam.find(p => p.team_name === selectedTeam)
    if (teamData) {
      const updatedTotal = data.reduce((sum, item) => {
        const teamPoint = item.point.find(
          point => point.team_name === selectedTeam,
        )
        const newValue = scores[item.id]?.[selectedTeam]
        if (newValue !== undefined) {
          return sum + newValue
        }
        return sum + (teamPoint?.value || 0)
      }, 0)
      setDataTotal({ team_name: selectedTeam, value: updatedTotal })
    }
  }
  const HandleSubmit = async () => {
    try {
      setLoading(true)

      const formattedData = data.map(item => {
        if (item.children.length === 0) {
          const updatedPoints = item.point.map(point => {
            const newValue = scores[item.id]?.[point.team_name]
            if (newValue !== undefined) {
              return { ...point, value: newValue }
            }
            return point
          })
          return {
            ...item,
            point: updatedPoints,
            children: item.children?.map(child => {
              const updatedChildPoints = child.point.map(point => {
                const newValue = scores[child.id]?.[point.team_name]
                if (newValue !== undefined) {
                  return { ...point, value: newValue }
                }
                return point
              })
              return { ...child, point: updatedChildPoints }
            }),
          }
        } else {
          return {
            ...item,
            // point: updatedPoints,
            children: item.children?.map(child => {
              const updatedChildPoints = child.point.map(point => {
                const newValue = scores[child.id]?.[point.team_name]
                if (newValue !== undefined) {
                  return { ...point, value: newValue }
                }
                return point
              })
              return { ...child, point: updatedChildPoints }
            }),
          }
        }
        // console.log(!!item.children.length > 0)
      })
      const res = await PractiseService.addPointToTeam({
        data: formattedData,
        practise_id: id,
        user_id: userInfo?.id,
        team_type: pagination.team_type,
      })
      if (res.isOk) {
        Notice({ msg: res.message, isSuccess: true })
        getDataPdf()
        handleTeamTotalUpdate()
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleTeamChange = team => {
    setSelectedTeam(team)
    const teamPoint = total.listTeam.find(p => p.team_name === team)
    setDataTotal(teamPoint)
  }

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (val, record, idx) => (
        <div className="text-center">
          {record.criteria_level === 1 &&
            (pagination.page_number - 1) * pagination.page_size + idx + 1}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên tiêu chí",
      dataIndex: "title",
      key: "title",
      width: 300,
      align: "center",
    },
    {
      title: (
        <>
          <MainTableData>Điểm quy định</MainTableData>
        </>
      ),
      align: "center",
      key: "score",
      render: (val, record) => (
        <Tooltip
          title={
            <>
              <div>Điểm: {record?.score}</div>
            </>
          }
        >
          <MainTableData>{record?.score}</MainTableData>
        </Tooltip>
      ),
    },
    // Show only the selected team column
    selectedTeam && {
      title: selectedTeam,
      dataIndex: `team_${selectedTeam}`,
      key: `team_${selectedTeam}`,
      render: (val, record) => {
        if (record.children && record.children.length > 0) {
          return null
        } else {
          return renderPointValues(record, selectedTeam)
        }
      },
      width: 120,
    },
  ].filter(Boolean) // Filter out undefined column when no team is selected
  const expandedRowRender = record => {
    const expandedColumns = [
      ...columns.slice(0, -1), // Use all columns except for the team column
      {
        title: selectedTeam,
        dataIndex: `team_${selectedTeam}`,
        key: `team_${selectedTeam}`,
        render: (val, record) => renderPointValues(record, selectedTeam),
        width: 120,
      },
    ]

    // return (
    //   <TableCustom
    //     isPrimary
    //     columns={expandedColumns}
    //     dataSource={record.children}
    //     pagination={false}
    //   />
    // )
  }

  const columnsRatings = [
    {
      title: "Xếp hạng",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (val, record, idx) => (
        <div className="text-center">
          {(pagination.page_number - 1) * pagination.page_size + idx + 1}
        </div>
      ),
      align: "center",
    },
    {
      title: "Tên đội",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Điểm",
      dataIndex: "score",
      key: "score",
      width: 300,
      align: "center",
    },
  ]

  return (
    <div>
      <Row gutter={[15, 15]} className="mr-10 mb-20">
        <Col span={24}>
          <Row gutter={[10]}>
            <Col span={18}></Col>
          </Row>
        </Col>
        <Col span={12}>
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: "#01638d",
                  itemSelectedColor: "#ffffff",
                },
              },
            }}
          >
            <Segmented
              defaultValue="Đội tấn công"
              options={["Đội tấn công", "Đội ứng cứu sự cố", "Bảng xếp hạng"]}
              onChange={value => {
                switch (value) {
                  case "Đội tấn công":
                    setPagination({
                      ...pagination,
                      team_type: 1,
                    })
                    setSelectedTeam(null)
                    setDataTotal()
                    break
                  case "Đội ứng cứu sự cố":
                    setPagination({
                      ...pagination,
                      team_type: 2,
                    })
                    setSelectedTeam(null)
                    setDataTotal()
                    break
                  case "Bảng xếp hạng":
                    setPagination({
                      ...pagination,
                      team_type: 3,
                    })
                    setSelectedTeam(null)
                    setDataTotal()
                    break
                }
              }}
              block
            />
          </ConfigProvider>
        </Col>

        {pagination.team_type !== 3 && (
          <>
            <Col
              span={12}
              style={{
                justifyContent: "end",
              }}
              className="d-flex "
            >
              <Select
                placeholder="Chọn tên đội"
                onChange={handleTeamChange}
                style={{ width: 200 }}
                value={selectedTeam}
              >
                {teamNames.map(team => (
                  <Select.Option key={team} value={team}>
                    {team}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={14} className="mt-10">
              {/* <StyleSearch>
                <Search
                  placeholder="Nhập tên cần tìm kiếm"
                  onSearch={event => {
                    setPagination({
                      ...pagination,
                      input: event,
                    })
                  }}
                  allowClear
                />
              </StyleSearch> */}
            </Col>
            <Col
              span={10}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {!!dataTotal && (
                <>
                  <div
                    className="total-group"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Tổng điểm đội {dataTotal?.team_name} : {dataTotal.value}{" "}
                    điểm
                  </div>
                </>
              )}
            </Col>
            <Col span={24} className="mr-10">
              <Form form={form} component={false}>
                <TableCustom
                  columns={columns}
                  isPrimary
                  dataSource={data.map(item => ({
                    ...item,
                    key: item.id,
                  }))}
                  loading={loading}
                  pagination={{
                    hideOnSinglePage: total <= 10,
                    current: pagination.page_number,
                    PageSize: pagination.page_size,
                    responsive: true,
                    total,
                    showSizeChanger: total > 10,
                    onChange: (page, page_size) => {
                      setPagination({
                        ...pagination,
                        page_number: page,
                        page_size: page_size,
                      })
                    },
                  }}
                  expandable={{
                    expandedRowRender,
                    rowExpandable: record =>
                      record.children && record.children.length > 0,
                  }}
                />
              </Form>
            </Col>

            {button?.btn_PushAll && (
              <Col span={24}>
                <Space>
                  <Button
                    btnType="primary"
                    disabled={loading}
                    // onClick={HandleSubmit}
                  >
                    Lưu nháp
                  </Button>
                  <Button
                    btnType="primary"
                    disabled={loading}
                    // onClick={HandleSubmit}
                  >
                    Công bố chi tiết
                  </Button>
                  <Button
                    btnType="primary"
                    disabled={loading}
                    onClick={() => {
                      handleResult()
                    }}
                  >
                    Công bố tổng
                  </Button>
                  <Button
                    btnType="primary"
                    disabled={loading}
                    onClick={HandleSubmit}
                  >
                    Lưu
                  </Button>
                </Space>
              </Col>
            )}
          </>
        )}
        {pagination.team_type === 3 && (
          <Col span={24}>
            <TableCustom
              isPrimary
              columns={columnsRatings}
              dataSource={dataRanking}
              loading={loading}
              pagination={{
                hideOnSinglePage: total <= 10,
                current: pagination.page_number,
                PageSize: pagination.page_size,
                responsive: true,
                total,
                showSizeChanger: total > 10,
                onChange: (page, page_size) => {
                  setPagination({
                    ...pagination,
                    page_number: page,
                    page_size: page_size,
                  })
                },
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Evaluate
