import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
} from "antd"
import React from "react"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import STORAGE, { getStorage } from "src/lib/storage"
import { getRegexPhoneNumber } from "src/lib/stringsUtils"
import FormService from "src/services/FormSevice"

const FormCommit = ({
  open,
  onOk,
  onCancel,
  onCancelCommit,
  onCancelCommitOne,
}) => {
  const [form] = Form.useForm()
  const handleSubmit = async () => {
    const userInfo = getStorage(STORAGE.USER_INFO)

    const values = await form?.validateFields()
    let body = {
      ...values,
      ...open,
      cur_date: !!values?.cur_date
        ? values.cur_date.format("YYYY-MM-DD HH:mm")
        : undefined,
      user_id: userInfo?.id,
    }
    const res = await FormService.createFormBM05(body)

    if (res.isOk) {
      Notice({ isSuccess: true, msg: res.message })
      onCancel()
      onCancelCommit()
      onCancelCommitOne()
    }
  }
  return (
    <div>
      <Modal
        title={"Cam kết bảo mật thông tin"}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
        footer={
          <Space>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              // onClick={() => handleChangeSubmit()}
            >
              Xem trước PDF
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              htmlType="submit"
              // onClick={() => handleChangeSubmit()}
            >
              Tải xuống
            </Button>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              type="submit"
              htmlType="submit"
              onClick={() => handleSubmit()}
            >
              Ghi lại
            </Button>
          </Space>
        }
      >
        <AdminStyled>
          <Form
            form={form}
            layout="vertical"
            className="mr-20"
            scrollToFirstError
          >
            <Row gutter={[30, 10]}>
              {/* <Col span={12}>
                <Form.Item
                  label="Thời gian cam kết"
                  name="cur_date"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD " />
                </Form.Item>
              </Col> */}

              <Col span={24}>
                <Form.Item
                  label="Địa điểm"
                  name="cur_addr"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <h3>Bên A:...</h3>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="1. Đại diện bên A"
                  name="a_presentation"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="2. Chức vụ" name="a_pre_pos">
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="3. Điện thoại"
                  name="a_phone"
                  rules={[
                    {
                      pattern: getRegexPhoneNumber(),
                      message: "Số điện thoại sai định dạng",
                    },
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="4. Địa chỉ"
                  name="a_addr"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <h3>Bên B:</h3>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="1. Điện thoại"
                  name="b_phone"
                  rules={[
                    {
                      pattern: getRegexPhoneNumber(),
                      message: "Số điện thoại sai định dạng",
                    },
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="2. Địa chỉ"
                  name="b_addr"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Là bên có khả năng về chuyên môn và kỹ thuật để có thể tham gi đội"
                  name="attack_tick"
                >
                  <Radio.Group
                    options={[
                      {
                        label: "Đội tấn công",
                        value: "Đội tấn công",
                      },
                      {
                        label: "Đội phòng thủ",
                        value: "Đội phòng thủ",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Tên hệ thống"
                  name="system_name"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Tiền phạt nếu vi phạm cam kết (VNĐ)"
                  name="money"
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={require("./imgcomment.png")}
                />
              </Col>
              <Col span={12}>
                <div className="sign-title">
                  <h4>Đại diện bên A</h4>
                </div>
                <div className="sign-title">
                  <Button
                    btnType="primary"
                    className="btn-hover-shadow"
                    type="submit"
                    // onClick={() => handleChangeSubmit()}
                  >
                    Ký tên
                  </Button>
                </div>
              </Col>
              <Col span={12}>
                <div className="sign-title">
                  <h4>Đại diện bên b</h4>
                </div>
                <div className="sign-title">
                  <Button
                    btnType="primary"
                    className="btn-hover-shadow"
                    type="submit"
                    // onClick={() => handleChangeSubmit()}
                  >
                    Ký tên
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </AdminStyled>
      </Modal>
    </div>
  )
}

export default FormCommit
