import { InboxOutlined } from "@ant-design/icons"
import { Col, Form, message, Modal, Row, Spin } from "antd"
import Dragger from "antd/es/upload/Dragger"
import { useState } from "react"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import { normFile } from "src/lib/utils"
import ContributeServiceUser from "src/services/ContributeServiceUser"

const UploadFile = ({ open, onOk, onCancel, getDataList }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)

  const handleChangeSubmit = async () => {
    if (!fileToUpload) {
      message.error("Please select a file before submitting.")
      return
    }

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("file_list", fileToUpload)
      formData.append("practise_id", open?.practise_id)

      const res = await ContributeServiceUser.UploadReports(formData)

      if (!res.isdata) {
        message.error(`${fileToUpload.name} file upload failed.`)
        return
      }

      setFileToUpload(null) // Clear the selected file after successful upload

      Notice({
        isSuccess: true,
        msg: res?.message,
      })
      getDataList()
      onCancel() // Close the modal
    } finally {
      setLoading(false)
    }
  }

  const props = {
    name: "file_list",
    multiple: false,
    accept: ".xlsx",
    beforeUpload: file => {
      setFileToUpload(file)
      return false // Prevent auto-upload
    },
    onRemove: file => {
      setFileToUpload(null) // Clear selected file when removed
    },
  }

  return (
    <Modal
      title="Nhập file báo cáo"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      width={800}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleChangeSubmit}
            btnType="primary"
            className="btn-hover-shadow"
            type="submit"
            loading={loading}
          >
            Gửi File
          </Button>
        </div>
      }
    >
      <Spin spinning={false}>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Upload tài liệu"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                name="docs"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value?.find(i => i?.size > 5 * 1024 * 1024)) {
                        return Promise.reject(
                          new Error("Dung lượng file tối đa 5MB"),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Dragger maxCount={1} {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Kéo thả file đính kèm hoặc Chọn File
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default UploadFile
