import { Checkbox, Col, DatePicker, Form, Input, Radio, Row, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { StyleFileBM02 } from "../../styled"
import Button from "src/components/MyButton/Button"
import FormService from "src/services/FormSevice"
import Notice from "src/components/Notice"
import { useParams } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import dayjs from "dayjs"
import { EditorState, ContentState } from "draft-js"
import ReusableEditor from "src/components/ReusableEditor"

const FileBM02 = ({ next }) => {
  const [editorStates, setEditorStates] = useState({
    system_name: EditorState.createEmpty(),
    ip_list: EditorState.createEmpty(),
    domain: EditorState.createEmpty(),
    target: EditorState.createEmpty(),
    propose: EditorState.createEmpty(),
  })
  const { id } = useParams()
  const userInfo = getStorage(STORAGE.USER_INFO)
  const [value, setValue] = useState()
  const [checkInput, setCheckInput] = useState(true)
  const [form] = Form.useForm()
  const { RangePicker } = DatePicker
  const CheckboxGroup = Checkbox.Group
  const [loading, setLoading] = useState(false)
  const plainOptions1 = [
    {
      label: "Web server",
      value: "Web server",
    },
    {
      label: "Mail server",
      value: "Mail server",
    },
    {
      label: "Database server",
      value: "Database server",
    },
    {
      label: "Dịch vụ khác",
      value: "Dịch vụ khác",
    },
  ]
  const defaultCheckedList1 = []
  const [checkedList1, setCheckedList1] = useState(defaultCheckedList1)
  const onChangeCheckBook1 = list => {
    setCheckedList1(list)
  }

  // security_measures
  const plainOptions = [
    "Antivirus",
    "Firewall",
    "Hệ thống phát hiện xâm nhập",
    "Khác",
  ]
  const defaultCheckedList = []
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const onChangeCheckBook = list => {
    setCheckedList(list)
  }

  const getDetailsForm = async () => {
    try {
      setLoading(true)
      const resp = await FormService.getFormByID({
        practise_id: id,
        form_type: 2,
      })

      form.setFieldsValue({
        ...resp.data.form_log,
        cur_date: !!resp.data.form_log.cur_date
          ? dayjs(resp.data.form_log.cur_date)
          : undefined,
        exercise_time: [
          resp.data.form_log.start_date
            ? dayjs(resp.data.form_log.start_date)
            : undefined,
          resp.data.form_log.end_date
            ? dayjs(resp.data.form_log.end_date)
            : undefined,
        ],
      })
      const updatedEditorStates = {}
      Object.keys(resp.data.form_log).forEach(key => {
        const text = resp.data.form_log[key]
        updatedEditorStates[key] = EditorState.createWithContent(
          ContentState.createFromText(
            text && typeof text === "string" ? text : "",
          ),
        )
      })

      setEditorStates(updatedEditorStates)
    } finally {
      setLoading(false)
    }
  }
  const handleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form?.validateFields()
      const requestBody = {
        ...values,
        practise_id: id,
        user_id: userInfo.id,
        domain: !!values?.domain?.blocks
          ? values?.domain?.blocks[0]?.text
          : values?.domain,
        start_date: !!values?.exercise_time
          ? values.exercise_time[0].format("YYYY-MM-DD HH:mm")
          : undefined,
        end_date: !!values?.exercise_time
          ? values.exercise_time[1].format("YYYY-MM-DD HH:mm")
          : undefined,
        ip_list: !!values?.ip_list?.blocks
          ? values?.ip_list?.blocks[0]?.text
          : values?.ip_list,
        propose: !!values?.propose?.blocks
          ? values?.propose?.blocks[0]?.text
          : values?.propose,
        system_name: !!values?.system_name?.blocks
          ? values?.system_name?.blocks[0]?.text
          : values?.system_name,
        target: !!values?.target?.blocks
          ? values?.target?.blocks[0]?.text
          : values?.target,
        cur_date: !!values?.cur_date
          ? values.cur_date.format("YYYY-MM-DD HH:mm")
          : undefined,
      }
      const res = await FormService.PostForms(requestBody)

      if (res.isOk) {
        Notice({ isSuccess: true, msg: res.message })
        getDetailsForm()
      } else {
        Notice({ isSuccess: true, msg: res.message })
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDetailsForm()
  }, [])

  return (
    <StyleFileBM02>
      <Form form={form} layout="vertical" scrollToFirstError>
        <Spin spinning={loading}>
          <Row gutter={[16, 0]} className="mr-40">
            <Col span={24}>
              <h2 className="tile-01">Báo cáo tổ chức diễn tập </h2>
            </Col>
            <Col span={24} style={{ fontWeight: "600" }}>
              THÔNG TIN VỀ HỆ THỐNG ĐƯỢC ĐƯA VÀO DIỄN TẬP
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="1. Đơn vị vận hành hệ thống thông tin"
                name="basis_name"
              >
                <Input placeholder="Nhập tên đươn vị vận hành hoặc đơn vị được thuê vận hành hệ thống thông tin"></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="2. Cơ quan chủ quản"
                name="co_quan_chu_quan"
              >
                <Input placeholder="Nhập"></Input>
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingBottom: "8px" }}>
              <span style={{ fontWeight: "600" }}>
                3. Hệ thống đưa vào diễn tập{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                >
                  {" "}
                  *{" "}
                </span>{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                (Điền tên các hệ thống được đưa vào diễn tập và địa chỉ IP liên
                quan)
              </span>
            </Col>

            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                name="system_name"
              >
                <ReusableEditor
                  value={form.getFieldValue("system_name")}
                  onChange={content =>
                    form.setFieldsValue({ system_name: content })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingBottom: "8px" }}>
              <span style={{ fontWeight: "600" }}>
                4. Phân loại cấp độ của hệ thống thông tin{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                (Nếu có)
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                // label="Họ và tên"
                name="level_tick"
              >
                <Radio.Group value={value}>
                  <Radio value={1}>Cấp độ 1</Radio>
                  <Radio value={2}>Cấp độ 2</Radio>
                  <Radio value={3}>Cấp độ 3</Radio>
                  <Radio value={4}>Cấp độ 4</Radio>
                  <Radio value={5}>Cấp độ 5</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24} style={{ fontWeight: "600" }}>
              THÔNG TIN CHI TIẾT VỀ HỆ THỐNG ĐƯỢC ĐƯA VÀO DIỄN TẬP
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="1. Hệ điều hành"
                name="os_name"
              >
                <Input placeholder="Nhập"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="2. Version"
                name="os_ver"
              >
                <Input placeholder="Nhập"></Input>
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingBottom: "8px" }}>
              <span style={{ fontWeight: "600" }}>
                2. Các dịch vụ có trên hệ thống{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                >
                  {" "}
                  *{" "}
                </span>{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                (Đánh dấu những dịch vụ được sử dụng trên hệ thống)
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                // label="Họ và tên"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                name="services_on_sys"
              >
                <CheckboxGroup
                  // defaultValue={
                  //   !!selectSecurity ? selectSecurity.services_on_sys : undefined
                  // }
                  options={plainOptions1}
                  value={checkedList1}
                  onChange={onChangeCheckBook1}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingBottom: "8px" }}>
              <span style={{ fontWeight: "600" }}>
                3. Các biện pháp an toàn thông tin đã triển khai{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                ></span>
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                (Đánh dấu những biện pháp đã triển khai)
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                // label="Họ và tên"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                name="security_measures"
              >
                <CheckboxGroup
                  // defaultValue={selectSecurity.security_measures}
                  options={plainOptions}
                  value={checkedList}
                  onChange={checkedValues => {
                    onChangeCheckBook(checkedValues)
                    if (checkedValues.includes("Khác")) {
                      setCheckInput(false)
                    } else {
                      setCheckInput(true)
                      form.setFieldsValue({ other_way_tick: "" }) // Xóa giá trị của form field
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="other_way_tick">
                <Input
                  disabled={checkInput}
                  placeholder="Nhập tên biện pháp triển khai khác"
                ></Input>
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "600" }}>
                4. Các địa chỉ IP của hệ thống{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                >
                  {" "}
                  *{" "}
                </span>{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                Liệt kê địa chỉ IP sử dụng trên Internet, không liệt kê địa chỉ
                IP nội bộ
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                name="ip_list"
              >
                <ReusableEditor
                  value={form.getFieldValue("ip_list")}
                  onChange={content =>
                    form.setFieldsValue({ ip_list: content })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="5. Các tên miền của hệ thống"
                name="domain"
              >
                <ReusableEditor
                  value={form.getFieldValue("domain")}
                  onChange={content => form.setFieldsValue({ domain: content })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="6. Mục đích chính của sử dụng hệ thống"
                name="target"
              >
                <ReusableEditor
                  value={form.getFieldValue("target")}
                  onChange={content => form.setFieldsValue({ target: content })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="7. Thời gian thực hiện diễn tập"
                name="exercise_time"
              >
                <RangePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={(value, dateString) => {
                    console.log("Selected Time: ", value)
                    console.log("Formatted Selected Time: ", dateString)
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "600" }}>
                8. Mô tả về kết quả diễn tập{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                >
                  {" "}
                  *{" "}
                </span>{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                Đề nghị cung cấp thông tin chi tiết về kết quả diễn tập, gồm:
              </span>
            </Col>
            <div className="boxSoilBottom m-8">
              <div className="p-16">
                <Col
                  span={24}
                  style={{ paddingBottom: "8px", display: "flex" }}
                >
                  <Form.Item
                    name="member_of_defense"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                    label="Số thành viên tham gia Đội phòng thủ:"
                  >
                    <Input placeholder="Nhập số thành viên" />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ paddingBottom: "8px", display: "flex" }}
                >
                  <Form.Item
                    name="member_of_attack_teams"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                    label="Số đội tấn công tham gia Đội tấn công"
                  >
                    <Input placeholder="Nhập số thành viên" />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ paddingBottom: "8px", display: "flex" }}
                >
                  <Form.Item
                    name="member_of_attack"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                    label="Số đội người tham gia Đội tấn công"
                  >
                    <Input placeholder="Nhập số thành viên" />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ paddingBottom: "8px", display: "flex" }}
                >
                  <Form.Item
                    name="defense_result"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                    label="Đánh giá về Đội phòng thủ thực hiện được (kết quả phát
                    hiện, điều tra, ngăn chặn, các điểm thành phần và tổng
                    điểm do BGK chấm)"
                  >
                    <Input placeholder="Nhập số thành viên" />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ paddingBottom: "8px", display: "flex" }}
                >
                  <Form.Item
                    name="attack_result"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                    label="Kết quả mỗi Đội tấn công thực hiện được (các lỗ hổng phát
                    hiện được, kết quả thành công của việc khai thác lỗ hổng
                    và tổng điểm đạt được do BGK chấm)"
                  >
                    <Input placeholder="Nhập đánh giá" />
                  </Form.Item>
                </Col>
              </div>
            </div>
            <Col
              span={24}
              style={{
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "600" }}>
                KIẾN NGHỊ, ĐỀ XUẤT HỖ TRỢ{" "}
              </span>
              <span style={{ color: "grey", fontStyle: "italic" }}>
                Đề nghị cung cấp tóm lược về các kiến nghị và đề xuất hỗ trợ
                trong tổ chức thực hiện diễn tập:
              </span>
            </Col>
            <Col span={24}>
              <Form.Item name="propose">
                <ReusableEditor
                  value={form.getFieldValue("propose")}
                  onChange={content =>
                    form.setFieldsValue({ propose: content })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
                label="THỜI GIAN THỰC HIỆN BÁO CÁO"
                name="cur_date"
              >
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "600" }}>
                CÁ NHÂN NGƯỜI ĐẠI DIỆN THEO PHÁP LUẬT{" "}
                <span
                  style={{ color: "red", fontStyle: "16px", margin: "0 5px" }}
                >
                  {" "}
                  *{" "}
                </span>
              </span>
            </Col>

            <Col span={24} className="d-flex">
              <Button btnType="primary" className="mr-40">
                {" "}
                Ký tên{" "}
              </Button>
              <Button btnType="primary"> Đóng dấu</Button>
            </Col>

            <Col span={24} className="d-flex ">
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "40px",
                  marginTop: "40px",
                  display: "flex",
                  width: "100%",
                  padding: "0px 15px 0px 0px",
                }}
              >
                <Button
                  className=""
                  style={{
                    marginRight: "8px",
                    background: "#248C4E",
                    color: "white",
                  }}
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Lưu
                </Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </StyleFileBM02>
  )
}

export default FileBM02
