import { Col, DatePicker, Form, Input, Modal, Row, Space } from "antd"
import React, { useState } from "react"
import { AdminStyled } from "src/components/Layouts/component/LayoutAdmin/styled"
import Button from "src/components/MyButton/Button"
import FormCommit from "./FormCommit"
import { regexIDCard } from "src/lib/stringsUtils"

const Commit = ({ open, onOk, onCancel, onCancelCommitOne }) => {
  const [form] = Form.useForm()
  const [isData, setIsData] = useState(false)
  const handleChangeSubmit = async () => {
    const values = await form?.validateFields()
    setIsData({
      ...values,
      practise_id: open,
      date_of_issue: !!values?.date_of_issue
        ? values.date_of_issue.format("YYYY-MM-DD HH:mm")
        : undefined,
    })
  }
  return (
    <div>
      <AdminStyled>
        <Modal
          title={"Cam kết bảo mật thông tin"}
          open={open}
          onOk={onOk}
          onCancel={onCancel}
          width={800}
          footer={
            <Space>
              <Button
                btnType="primary"
                className="btn-hover-shadow"
                type="submit"
                onClick={() => handleChangeSubmit()}
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </Space>
          }
        >
          <Row>
            <Col span={24}>
              <h3
                className="text-align-center"
                style={{
                  textAlign: "center",
                  color: "#00549A",
                }}
              >
                Diễn tập Thực chiến bảo đảm an toàn thông tin mạng Bộ Nội vụ năm
                2024
              </h3>
            </Col>
            <Col span={24}>
              <h4
                style={{
                  textAlign: "center",
                }}
              >
                Bạn đã được mời/duyệt tham gia diễn tập với vai trò : Đội tấn
                công
              </h4>
            </Col>
            <Col span={24}>
              <h5
                style={{
                  color: "#0E8CF1",
                }}
              >
                Xem chi tiết bản cam kết bảo mật thông tin
              </h5>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical" scrollToFirstError>
                <Form.Item
                  label="Số CCCD"
                  name="CCCD"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                    {
                      pattern: regexIDCard(),
                      message: "CCCD/CMT nhập sai định dạng!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
                <Form.Item
                  label="Ngày cấp"
                  name="date_of_issue"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD " />
                </Form.Item>
                <Form.Item
                  label="Nơi cấp"
                  name="place_of_issue"
                  rules={[
                    {
                      required: true,
                      message: "Không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập"></Input>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24}>
              <h4 style={{ color: "red" }}>
                Lưu ý: Khi Xác nhận gửi thông tin cũng đồng nghĩa với việc bạn
                đồng ý với các nội dung trong bản cam kết bảo mật thông tin của
                chúng tôi.
              </h4>
            </Col>
          </Row>
        </Modal>
      </AdminStyled>
      {!!isData && (
        <FormCommit
          open={isData}
          onOk={() => setIsData(false)}
          onCancel={() => setIsData(false)}
          onCancelCommit={onCancel}
          onCancelCommitOne={onCancelCommitOne}
        />
      )}
    </div>
  )
}

export default Commit
